// Converte o trecho dos submétodos para o formato da API
const normalizeSubMethods = ({ sub_methods = {} }, originalSubMethods) => {
  const filteredSubMethods = Object.entries(sub_methods).filter(
    ([, active]) => active,
  );

  return filteredSubMethods?.map(([id]) => {
    const { label, method } = originalSubMethods.find(({ _id }) => _id === id);

    return {
      label,
      method,
    };
  });
};

// Converte o trecho dos métodos para o formato da API
const normalizePaymentMethod = ({ methods = {} }, paymentMethodsList) =>
  Object.entries(methods)
    .filter(([, { active }]) => active)
    .map(([methodId, methodContent]) => {
      const flatPaymentMethods = Object.values(paymentMethodsList).flat();
      const { type, label, method, sub_methods } = flatPaymentMethods.find(
        ({ _id }) => _id === methodId,
      );

      return {
        type,
        label,
        method,
        sub_methods: normalizeSubMethods(methodContent, sub_methods),
      };
    });

// Converte a estrutura do formulário para o formato da API
export const normalizePaymentMethodsForm = (formData, paymentMethodsList) =>
  Object.entries(formData).reduce(
    (acc, [key, data]) => ({
      ...acc,
      [key]: normalizePaymentMethod(data, paymentMethodsList),
    }),
    {},
  );

// Converte o trecho da lista de submétodos para o formato do formulário
const normalizeFormDataSubMethods = subMethods =>
  subMethods.reduce(
    (prev, { _id, active }) => ({
      ...prev,
      [_id]: active,
    }),
    {},
  );

// Converte o trecho da lista de métodos para o formato do formulário
const normalizeFormDataMethods = methods =>
  methods.reduce(
    (prev, { _id, active, sub_methods }) => ({
      ...prev,
      [_id]: {
        active,
        sub_methods: normalizeFormDataSubMethods(sub_methods),
      },
    }),
    {},
  );

// Converte a estrutura recebida da API para o formato do formulário
export const normalizeInitialFormData = paymentMethods =>
  Object.entries(paymentMethods).reduce(
    (prev, [type, methods]) => ({
      ...prev,
      [type]: {
        methods: normalizeFormDataMethods(methods),
      },
    }),
    {},
  );

// Retorna se a configuração de uma location é local ou não
export const getIsLocalConfiguration = (locationId, paymentMethodsConfigList) =>
  paymentMethodsConfigList.some(
    ({ location_id }) => location_id === locationId,
  );

// Para padronizar variáveis recebidas pela api.
const PAYMENT_METHODS_TYPE_MAP = {
  onlineMethods: 'online_payment_methods',
  inPersonMethods: 'in_person_payment_methods',
  online_payment_methods: 'onlineMethods',
  in_person_payment_methods: 'inPersonMethods',
};

export const getPopulatedPaymentMethods = (
  paymentMethodsList,
  paymentMethodsConfigList,
  locationId = null,
) => {
  const isLocalConfiguration = getIsLocalConfiguration(
    locationId,
    paymentMethodsConfigList,
  );

  // Na paymentMethodsList separo por chave e valor, ficando um array de arrays onde a
  // posição 0 é a chave e a posição 1 é o array de métodos
  return Object.entries(paymentMethodsList).reduce(
    // reduce para transformar em objeto novamente e alterar o valor de cada chave
    (prev, [key, methods]) => {
      const type = PAYMENT_METHODS_TYPE_MAP[key];

      return {
        // spread no conteúdo já existente (acumulado no prev)
        ...prev,
        // adiciona a chave e itera por cada um dos methods
        [type]: methods.map(paymentMethod => {
          // verifica se o method atual também está no array de config (valor atual)
          const methodConfig = paymentMethodsConfigList
            .find(({ location_id }) =>
              isLocalConfiguration ? location_id === locationId : !location_id,
            )
            ?.[type].find(({ method }) => method === paymentMethod.method);

          // se estiver, usa o valor que já foi preenchido, senão usa o valor padrão (vazio)
          return {
            ...paymentMethod,
            active: methodConfig?.active || false,
            sub_methods: paymentMethod.sub_methods.map(subMethod => ({
              ...subMethod,
              active: methodConfig?.sub_methods?.some(
                ({ method }) => method === subMethod.method,
              ),
            })),
          };
        }),
      };
    },
    {},
  );
};
