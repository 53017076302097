import React from 'react';
import { FaSquare } from 'react-icons/fa';

import Doughnut from '~/components/charts/doughnut-chart';
import Panel from '~/components/Panel';
import Table from '~/components/Table';
import { formatMoney } from '~/helpers';

const schema = {
  platform: {
    render: () => <FaSquare className="text-primary" />,
    title: 'Plataforma',
  },
  revenue: {
    render: obj => obj.productName,
    title: 'Receita',
  },
  incomeValue: {
    render: obj => formatMoney(obj.incomeValue),
    title: 'Participação',
  },
};

const dataChart = {
  labels: ['Social', 'Search Engines', 'Direct', 'Other'],
  datasets: [
    {
      data: [260, 125, 54, 146],
    },
  ],
};

const OriginChartTable = ({ loading, dataChart, data = {} }) => (
  <Panel title="Origem" noPaddingBody isLoading={loading}>
    <div className="hold-small-chart">
      <Doughnut data={dataChart} />
    </div>
    <Table schema={schema} data={data.salesTableData} />
  </Panel>
);

export default OriginChartTable;
